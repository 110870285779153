.userInfo {
  &__head {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 20px 22px 0;
    margin-bottom: 10px;

    img {
      width: 135px;
      height: 135px;
      border-radius: 100%;
      object-fit: cover;
    }

    & &--info {
      flex: 1;

      h4 {
        font-size: 24px;
        font-weight: 700;
        margin: 0 0 5px;
      }

      p {
        font-size: 18px;
        color: #7d94ae;
        margin: 0;
      }
    }

    button {
      width: 68px;
      height: 31px;
      font-size: 16px;
      font-weight: 700;
      background: #fff;
      border: none;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
  }

  &__body {
    padding: 10px 0;

    &--description {
      margin-bottom: 26px;

      label {
        font-size: 16px;
        color: #7d94ae;
      }

      p {
        font-size: 16px;
        line-height: 1.25;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }

    &--item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;

      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }

      p,
      a {
        font-size: 16px;
        line-height: 1.25;
        text-decoration: none;
        color: #000;
        margin: 0;
      }
    }
  }
}
