@import "../common/ColorPallete";

.context-menu-container {
  .context-menu {
    position: fixed;
    width: 404px;
    height: auto;
    padding: 10px 0;
    background: rgba(255, 255, 255, 1);
    box-shadow:
      -2px -2px 10px 2px rgba(0, 0, 0, 0.2),
      2px 2px 4px 2px rgba(0, 0, 0, 0.25);
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 100%;
    }

    .context-menu-item {
      box-sizing: border-box;
      width: 100%;
      padding-bottom: 5px;
      display: flex;
      align-items: center;
      text-decoration: none;
      cursor: pointer;
      &.disabled {
        cursor: not-allowed;
        user-select: none;
        color: #ccc;
        .context-menu-icon {
          opacity: 0.25;
        }
      }

      .context-menu-item-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        z-index: 15;
        gap: 10px;
        padding: 4px 10px;
        .file-context-menu {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
        &:hover {
          background: $border-color;
        }
        .context-menu-icon {
          width: 20px;
          height: 20px;
        }
      }

      .content-context-menu {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
    .border-bottom {
      border-bottom: solid 1px $border-color;
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
}
