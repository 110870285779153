.preview-info-file {
  .note {
    display: flex;
    flex-direction: column;
    margin: 20px 0 10px 0;
    span {
      font-weight: 400;
      line-height: 19.2px;
      text-align: left;
      color: #7d94ae;
    }
  }
  .detail-file-preview {
    padding: 19px 3.5px;
    margin-top: 10px;
    .name-file-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      justify-content: space-between;
      .name-file {
        font-weight: 700;
        line-height: 21.6px;
        white-space: nowrap;
      }
      .active-lastest {
        font-size: 10px;
        font-weight: 400;
        color: rgba(125, 148, 174, 1);
        margin-left: 23.5px;
      }
    }
    .type-file {
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: rgba(18, 105, 115, 1);
    }
    .basic-info-wrapper {
      width: 100%;

      .info-detail-file {
        display: flex;
      }

      .info-left,
      .info-right {
        display: flex;
        align-items: flex-start;
        margin-right: 10px;
        flex-direction: column;
        gap: 3px;
        .detail {
          display: flex;
          color: rgba(125, 148, 174, 1);
          font-size: 12px;
          font-weight: 400;
          min-width: 98px;
        }
      }
      .info-left .detail .text {
        width: 40px;
      }
      .info-right .detail .text {
        width: 45px;
      }
    }
    .title-text {
      padding: 7px 0;
      font-weight: 700;
    }
    .history-wrapper {
      width: 100%;
      .history-item {
        display: flex;
        color: rgba(125, 148, 174, 1);
        font-size: 10px;
        font-weight: 400;
        margin-bottom: 8px;
        .text {
          min-width: 82px;
        }
      }
    }
  }
}
