@import "../common/Font.scss";

.repoList {
  & &__list {
    display: flex;
    flex-direction: column;
  }

  & &__item {
    display: flex;
    align-items: center;
    @include font-style(normal, $text-main-color);
    padding: 6px 5px;
    gap: 9px;
    cursor: pointer;
    border-left: 2px solid transparent;
    overflow: hidden;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #ccc;
      overflow: hidden;
      object-fit: cover;
    }

    p {
      flex: 1;
      margin: 0;
      word-break: break-all;
      color: $text-main-color;
    }

    &:hover {
      background: $hover-color;
      border-left: 2px solid $active-color;
    }
  }



  & &__text {
    color: #7d94ae;
    padding: 0 10px;
  }
}
