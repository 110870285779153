.main-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fbfbfb;
  cursor: pointer;
}

.header-logo {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  box-shadow:
    1px 1px 3px 0px rgba(228, 228, 228, 0.9),
    -1px -1px 2px 0px rgba(255, 255, 255, 0.9),
    1px -1px 2px 0px rgba(228, 228, 228, 0.2),
    -1px 1px 2px 0px rgba(228, 228, 228, 0.2),
    -1px -1px 2px 0px rgba(228, 228, 228, 0.5) inset,
    1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
  img {
    width: 18px;
    height: 16px;
    margin-left: 3px;
  }
}

.login-logo {
  width: 207px;
  height: 207px;
  box-shadow:
    5.16px 5.16px 15.47px 0px rgba(228, 228, 228, 0.9),
    -5.16px -5.16px 10.31px 0px rgba(255, 255, 255, 0.9),
    5.16px -5.16px 10.31px 0px rgba(228, 228, 228, 0.2),
    -5.16px 5.16px 10.31px 0px rgba(228, 228, 228, 0.2),
    -5.16px -5.16px 10.31px 0px rgba(228, 228, 228, 0.5) inset,
    5.16px 5.16px 10.31px 0px rgba(255, 255, 255, 0.3) inset;
  img {
    width: 93px;
    height: 83px;
    margin-left: 15px;
  }
}
