@import "ColorPallete";

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Thin.ttf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-ExtraLight.ttf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-SemiBold.ttf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-ExtraBold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Noto Sans JP";
  src: url("../../fonts/notosans-font/NotoSansJP-Black.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Light.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Black.ttf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-BlackItalic.ttf") format("opentype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-BoldItalic.ttf") format("opentype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-ExtraBold.ttf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-ExtraBoldItalic.ttf") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Italic.ttf") format("opentype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-LightItalic.ttf") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-MediumItalic.ttf") format("opentype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-Thin.ttf") format("opentype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("../../fonts/alegreya-font/AlegreyaSans-ThinItalic.ttf") format("opentype");
  font-weight: 100;
  font-style: italic;
}

$font-family-en: "Alegreya Sans";

$font-family-ja: "Noto Sans JP";

// Definition of Font Style
@mixin font-style($weight, $color) {
  font-weight: $weight;
  color: $color;
}

// Applying Font Style
.text-bold-black {
  @include font-style(bold, $text-main-color);
}

.text-regular-black {
  @include font-style(normal, $text-main-color);
}

.text-regular-lightgray {
  @include font-style(normal, $text-lightgray-color);
}

.text-regular-mainaccent {
  @include font-style(normal, $main-accent-color);
}

.text-bold-white {
  @include font-style(bold, $text-reverse-color);
}

.text-regular-white {
  @include font-style(normal, $text-reverse-color);
}
