@import "../common/Font.scss";

.repo-directory-page {
  .function-container {
    width: 50%;
    padding: 10px 20px;
    .wrapper-select-version {
      height: 73px;
      width: auto;
      display: flex;
      align-items: center;
      .dropdown-select-version {
        .switch-container {
          position: relative;
          display: inline-block;
        }
        .switch-toggle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 136px;
          max-width: 200px;
          height: 31px;
          padding: 5px 10px;
          border-radius: 5px;
          background: linear-gradient(135deg, #eeeeee 100%, #ffffff 0%);
          box-shadow:
            3px 3px 8px 0px rgba(227, 227, 227, 0.9),
            -3px -3px 6px 0px rgba(255, 255, 255, 0.9),
            3px -3px 6px 0px rgba(227, 227, 227, 0.2),
            -3px 3px 6px 0px rgba(227, 227, 227, 0.2),
            -1px -1px 2px 0px rgba(227, 227, 227, 0.5) inset,
            1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
          cursor: pointer;
          user-select: none;

          .text-switch {
            height: 16px;
            line-height: 19.2px;
            margin: 0 5px;
            font-size: 16px;
            font-weight: 700;
            flex: 1;
          }
          .icon-shuffle {
            height: 25px;
          }
          .icon-select-triagle {
            height: 15px;
          }
        }

        .switch-dropdown {
          position: absolute;
          width: 100%;
          top: 100%;
          left: 0;
          z-index: 1;
          background-color: #fff;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          border-radius: 4px;
          max-height: 250px;
          overflow-y: auto;
        }

        .switch-option {
          padding: 8px 12px;
          cursor: default;
          user-select: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          p {
            white-space: nowrap;
          }
        }

        .switch-option:hover {
          background-color: #f0f0f0;
        }

        .no-option {
          padding: 20px 12px;
          color: #ccc;
          text-align: center;
          user-select: none;
        }
      }
      .button-save-version {
        height: 31px;
        border-radius: 5px;
        background-color: rgba(4, 143, 173, 1);
        color: #fff;
        font-weight: 700;
        line-height: 8px;
        text-align: center;
        cursor: pointer;
        margin-left: 15px;
        padding: 10px 15px;
        display: flex;
        align-items: center;
        box-shadow:
          2px 2px 4px 0 rgba(0, 0, 0, 0.2),
          2px 2px 8px 0 #fff;
      }
      .button-view-only {
        background-color: #00dba6;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: default;
      }
    }
  }

  .container-directory {
    width: 100%;
    .container-wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .container-left {
        padding: 10px;
        .search-file {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .input-wrapper {
            width: 100%;
            height: 52px;
            padding: 0px 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            border: 1px solid $border-color;
            .dropdown-type {
              width: auto;
              height: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 5px 10px;
              border-right: 1px solid $border-color;
              box-sizing: border-box;
              .text-dropdown {
                font-size: 16px;
                font-weight: 700;
              }
              .icon-select-triangle {
                margin-left: 8px;
                margin-top: 5px;
              }
            }
          }
          .container {
            position: absolute;
            box-sizing: border-box;
            border: 1px solid #126973;
            border-radius: 6px;
            background-color: #fbfbfb;
            padding: 5px;
            top: 50px;
            left: 0;
            .child-wrapper {
              display: flex;
              justify-content: space-between;
              box-sizing: border-box;
              padding: 0 10px;
              border: 1px solid transparent;
              min-width: 500px;
              .go-to-folder {
                display: none;
                cursor: pointer;
              }
              .go-to-folder:hover {
                font-weight: 700;
              }
            }
            .child-wrapper:hover {
              border-radius: 6px;
              border-color: #126973;
              .go-to-folder {
                display: block;
              }
            }
            .item {
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              .child-name {
                display: flex;
                align-items: center;
                box-sizing: border-box;
                padding: 0 10px;

                min-width: 200px;
                span:nth-child(3) {
                  font-weight: 700;
                }
              }
            }
          }
        }
        .button-collapse {
          width: 107px;
          height: 31px;
          border-radius: 5px;
          background-color: rgba(4, 143, 173, 1);
          color: #fff;
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
          margin-left: 15px;
          padding: 2px 4px;
        }
        .icon-add-file {
          position: relative;
          overflow: hidden;
          padding: 10px;
          display: flex;
          align-items: center;
          .file-input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
          img {
            display: block;
          }
        }
        .bar-log {
          width: 100%;
          border-radius: 6px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #636c76;
          padding: 20px 15px;
          height: 62px;
          box-sizing: border-box;
          background-color: #fbfbfb;
          border: 1px solid $border-color;
          div {
            margin-right: 10px;
          }
          .type-change,
          .time-commit {
            color: #7d94ae;
          }
          .bar-log-left {
            display: flex;
            align-items: center;
            .checkbox-all {
              width: 18px;
              height: 18px;
            }
            .action-file-warpper {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
            .additional-checkbox {
              margin: -12px -0;
              margin-left: -10px;
            }
            .avatar-git {
              width: 20px;
              height: 20px;
              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
            .name-git {
              color: #000;
              font-weight: 500;
            }
            .menu {
              position: absolute;
              background-color: white;
              border: 1px solid #ccc;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              padding: 5px 0;
              z-index: 1000; /* Đảm bảo menu hiển thị trên cùng */
            }

            .menu-item {
              padding: 8px 16px;
              cursor: pointer;
              margin-right: 0;
            }

            .menu-item:hover {
              background-color: #f0f0f0;
            }
          }
          .empty-repository {
            font-weight: 700;
            line-height: 19.2px;
            text-align: left;
            color: #000000;
          }
        }
        .table-folder-structure {
          display: block;
          position: relative;
          min-height: calc(100% - 62px);
          &.empty-repo-table {
            min-height: 227px;
          }
          .loading-container {
            min-height: 300px;
          }
          table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0px;
            table-layout: fixed;
            border-radius: 6px;
            overflow: hidden;
          }
          .dragging + table {
            opacity: 0.2;
          }
        }
        .start-empty-repo {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 10px;
          .action {
            position: relative;
            padding: 10px;
            margin-bottom: 20px;
            box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            .description {
              display: flex;
              flex-direction: column;
              margin-bottom: 8.5px;
              .title {
                font-weight: 700;
                line-height: 1.2;
                text-align: left;
                color: #000000;
                display: flex;
                align-items: center;
                gap: 18px;
                margin-bottom: 5px;
                img {
                  width: 25px;
                  height: 25px;
                }
              }
              .note {
                font-weight: 400;
                line-height: 19.2px;
                text-align: left;
                color: #7d94ae;
                padding-left: 5px;
              }
            }

            .action-icon-close {
              position: absolute;
              right: 10px;
              top: 10px;
              cursor: pointer;
            }

            .action-wrap-button {
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 18px;

              button {
                padding-left: 13.5px;
                padding-right: 13.5px;
                height: 31px;
              }

              button.btn-skip {
                width: 56px;
                padding: 0;
                background-color: #fff;
                color: #000;
              }
            }
          }

          .text-follow {
            line-height: 1.2;
            font-weight: 700;
            color: #000;
            padding: 21px 12px;
            border-radius: 5px;
            border: 1px solid $border-color;
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
      .container-right {
        padding: 10px;
      }
    }
  }
}
