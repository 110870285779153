@import "../common/Font.scss";

.ticket-chat-box {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 30;
  height: 100%;
  max-height: calc(100vh - 120px);
  .chat-main {
    height: 100%;
    background-color: #ffffff;
    overflow-y: auto;
    padding: 0;
    flex-grow: 1;
    .time-separate {
      height: 0.5px;
      background-color: $border-color;
      z-index: 1;
      position: relative;
      margin: 12px 4px;
      span.text {
        font-size: 8px;
        font-weight: 400;
        line-height: 9.6px;
        color: $border-color;
        z-index: 2;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 2px;
      }
    }

    .container-chat {
      display: flex;
      margin-bottom: 5px;
      align-items: start;
      .box-avatar {
        background-color: #d9d9d9;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        margin-left: 10px;
      }
      .container-content-chat {
        width: 65%;
        .name {
          font-size: 8px;
          color: #7d94ae;
          margin-bottom: 6px;
          max-width: 300px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .chat-bubble {
          background-color: #d1e7e9;
          color: #000000;
          padding: 10px;
          max-width: 300px;
          position: relative;
          border-radius: 10px;
          font-size: 10px;

          &.chat-bubble-owner {
            margin-top: 20px;
            margin-right: 8px;

            &::after {
              content: "";
              position: absolute;
              left: 98%;
              top: 5px;
              width: 0;
              height: 0;
              border-top: 0px solid transparent;
              border-bottom: 10px solid transparent;
              border-left: 18px solid #d1e7e9;
              transform: rotate(-15deg);
            }
          }

          &.chat-bubble-away {
            margin-top: 10px;
            margin-left: 10px;
            background: #fbfbfb;

            &::after {
              content: "";
              position: absolute;
              right: 98%;
              top: 5px;
              width: 0;
              height: 0;
              border-top: 0px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 18px solid #fbfbfb;
              transform: rotate(15deg);
            }
          }

          .content {
            white-space: pre-line;
            word-wrap: break-word;
            margin: 0;
          }
          .list-img {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            margin-top: 5px;
            img {
              width: 100%;
              height: auto;
              object-fit: cover;
            }
          }
        }
      }

      .time {
        font-size: 8px;
        color: #7d94ae;
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
        padding: 2px 0;
        height: 21px !important;
        box-sizing: content-box;
      }
    }
  }

  .chat-action {
    z-index: 40;
    width: calc(25% - 16px);
    margin-right: 6px;
    border-radius: 5px;
    min-height: 108px;
    margin-top: 12px;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #fbfbfb;
    box-shadow: $box-shadow-input;

    textarea {
      width: 100%;
      font-size: 12px;
      box-sizing: border-box;
      border: 0;
      background-color: transparent;
      resize: none;
      padding: 2px 4px 0px 4px;
      height: 64px;
      &:focus-visible {
        outline: none;
        border: none;
      }
      &:focus {
        border: none;
      }
      &::placeholder {
        font-size: 12px;
        font-weight: 400;
        line-height: 19.2px;
        color: #7d94ae;
      }
    }
    .container-image {
      display: flex;
      flex-wrap: wrap;
      margin: 5px;
      background: $background-input;
    }
    .action {
      display: flex;
      background: $background-input;
      justify-content: space-between;
      align-items: center;
      padding: 0 5px 4px 5px;
    }
    .btn-file {
      padding: 0;
      width: 20px;
      height: 16px;
      img {
        width: 20px;
        height: 16px;
      }
    }
    .img-preview {
      display: flex;
      align-items: center;
      padding: 0;
      border-radius: 5px;
      position: relative;
      width: auto;
      width: 40px;
      margin-right: 20px;
      margin-bottom: 10px;

      .close {
        border-radius: 50%;
        padding: 2px 4px;
        position: absolute;
        top: -10px;
        left: 32px;
        background-color: #7d94ae;
        width: 24px;
        height: 24px;
      }
      img {
        object-fit: cover;
      }
    }
    .btn-send {
      right: 10px;
      padding: 4px;

      img {
        width: 17px;
        height: 17px;
      }
    }
  }
}
