@import "../common/Font.scss";

.settingLayout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  & &__wrapper {
    flex: 1;
  }

  & &__sider {
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
    padding: 20px 10px 0 40px;
    border-right: 1px solid $border-color;

    button {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #000;
      background: #fff;
      height: 32px;
      border-left: 2px solid transparent;
    }

    button.selected {
      border-left: 2px solid $active-color;
      background: $hover-color;
    }
  }

  & &__content {
    padding: 20px 20px 20px 30px;
    width: 100%;
    max-width: 862px;

    h2 {
      margin: 0;
      line-height: 1;
      padding-bottom: 11px;
      border-bottom: 1px solid $border-color;
    }
  }

  & &__line {
    height: 1px;
    width: 100%;
    background-color: $border-color;
  }
}

.generalSetting {
  h3 {
    margin-top: 14px;
  }
}

.eNotify {
  * {
    box-sizing: border-box !important;
  }

  & &__desc {
    padding: 8px 4px 6px;
  }

  & &__text {
    color: #7d94ae;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.2;
  }

  & &__form {
    background: #fbfbfb;
    margin-bottom: 8px;
  }

  & &__field {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 3px;

    span {
      line-height: 1.2;
      font-weight: 700;
    }

    div {
      padding: 0 4px;
    }

    input {
      color: #000;
      background: #fbfbfb;
      height: 31px;
      width: 100%;
      max-width: 630px;
      padding: 2px 12px;
      border: none;
      outline: none;
      border-radius: 5px;
      box-shadow: $box-shadow-input;

      &::placeholder {
        color: #7d94ae;
      }
    }

    input.input-error {
      border: 1px solid red;
    }

    p {
      padding-top: 2px;
    }
  }

  & &__textError {
    display: block;
    margin-top: 4px;
    color: red;
    font-weight: 400 !important;
  }

  & &__foot {
    padding: 6px 12px;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  & &__checkbox {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 8px;

    button {
      width: 20px;
      height: 20px;
      background: none;
      padding: 0;
    }

    span {
      font-weight: 700;
      color: #000;
    }
  }

  & &__submit {
    display: flex;
    justify-content: flex-end;

    button {
      width: 155px;
      height: 31px;
    }
  }
}
