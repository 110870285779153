.cardRepo {
  padding: 15px 12px;
  box-shadow:
    3px 3px 8px 0px rgba(237, 237, 237, 0.9),
    -3px -3px 6px 0px rgba(255, 255, 255, 0.9),
    3px -3px 6px 0px rgba(237, 237, 237, 0.2),
    -3px 3px 6px 0px rgba(237, 237, 237, 0.2),
    -1px -1px 2px 0px rgba(237, 237, 237, 0.5) inset,
    1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
  cursor: pointer;

  &__head {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 16px;
    }

    span:first-child {
      font-weight: 700;
    }

    &--directory {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__body {
    &--content {
      .preview-file-card.preview-file-container {
        .preview-file-main {
          max-height: 170px;
          min-height: 170px;
        }
      }
      .preview-file-info-card.preview-info-file {
        .detail-file-preview {
          .update-history-wrapper {
            display: none;
          }
          .basic-info-wrapper {
            .info-detail-file {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
