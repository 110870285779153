.preview-file-container {
  position: relative;
  .header-preview {
    padding: 10px 15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    z-index: 10;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
  .header-markdown-preview {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
  }
  .preview-file-main {
    display: flex;
    width: 100%;
    min-height: 300px;
  }
  .note {
    margin: 10px 15px;
    font-weight: 500;
    line-height: 16.8px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container-preview {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .loading-model {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
}
.play-back-section {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  width: 95%;
  height: 7px;
  input {
    width: 100%;
  }
  .action-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    margin-left: 16px;
    width: 30px;
    height: 30px;
    img {
      cursor: pointer;
    }
  }
  .time-group {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    .space-time {
      margin: 0 6px;
    }
  }
}

input.custom-range {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;

  /* Track: webkit browsers */
  &::-webkit-slider-runnable-track {
    height: 3px;
    background: #ccc;
    border-radius: 16px;
  }
  /* Track: Mozilla Firefox */
  &::-moz-range-track {
    height: 3px;
    background: #ccc;
    border-radius: 16px;
  }

  &::-webkit-slider-thumb {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    border-radius: 50%;
    /*  slider progress trick  */
    box-shadow: -390px 0 0 400px #000;
  }
}
.display-none {
  display: none;
}
