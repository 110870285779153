@import "../common/ColorPallete";

.enhancePage {
  * {
    box-sizing: border-box;
  }

  & &__title {
    h3 {
      font-weight: 700;
      color: #000;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  & &__desc {
    line-height: 1.2;
    color: #7d94ae;
    margin-top: 0;
    margin-bottom: 12px;
  }

  & &__line {
    height: 1px;
    background-color: $border-color;
  }

  & &__step {
    &--item {
      margin-top: 10px;
      margin-bottom: 22px;

      h5 {
        color: #000;
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 8px;
      }

      div {
        display: flex;
        justify-content: flex-end;

        button {
          background-color: #048fad;
          color: #fff;
          font-weight: 700;
          height: 31px;
          width: 138px;
          border-radius: 5px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
        }
      }
    }
  }

  & &__repo {
    h4 {
      font-weight: 700;
      color: #000;
      margin-top: 0;
      margin-bottom: 4px;
    }

    &--body {
      border: 1px solid $border-color;
      border-radius: 5px;
      max-height: 95px;
      overflow-y: auto;
    }
  }
}
