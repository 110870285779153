@import "../common/Font.scss";

.pManage {
  padding-top: 26px;
  max-width: 800px;

  & &__buttonAdd {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;

    button {
      width: 118px;
    }
  }

  & &__search {
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 14px;
  }

  & &__checkboxAll {
    display: flex;
    align-items: center;
    gap: 6px;

    span {
      font-weight: 700;
    }
  }

  & &__iconAction {
    padding-top: 7px;
    margin-left: 4px;
    position: relative;
  }

  & &__menuAction {
    width: 110px;
    position: absolute;
    top: 100%;
    left: 0;
    background: #fbfbfb;
    border-radius: 3px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;

    div {
      padding: 10px;
      font-weight: 400;
      text-align: left;
      color: black;
      cursor: pointer;

      &:hover {
        background-color: rgba(125, 148, 174, 0.2);
      }
    }
  }

  & &__item {
    height: 70px;
    padding: 14px 21px 14px 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;

    &--checkbox {
      margin-right: 15px;
    }

    &--avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: #d9d9d9;
      margin-right: 13px;
    }

    &--name {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        font-size: 16px;
        line-height: 1;
        font-weight: 700;
        color: #241d6e;
        border-bottom: 1px solid #241d6e;
        display: inline;
        margin-top: 0;
        margin-bottom: 10px;
        text-decoration: none;
      }

      span {
        font-size: 12px;
        color: #7d94ae;
      }
    }

    &--role {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fbfbfb;
      width: 92px;
      height: 32px;
      font-size: 12px;
      padding: 0 7px 0 9px;
      margin-right: 21px;
      position: relative;
      user-select: none;
      cursor: pointer;

      span {
        color: #7d94ae;
      }
    }

    &--dropdown {
      width: 100%;
      position: absolute;
      top: 100%;
      left: 0;
      background: #fbfbfb;
      border-radius: 3px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      width: 100%;
      z-index: 1;
      user-select: none;

      div {
        padding: 10px;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: black;
        cursor: pointer;

        &:hover {
          background-color: rgba(125, 148, 174, 0.2);
        }
      }
    }
  }
}
