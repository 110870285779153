@import "../common/Font";

.container-login {
  height: calc(100vh - 80px);
  .logout-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $main-color;
    color: $text-reverse-color;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($main-color, 10%);
    }
  }

  .login-button {
    min-width: 314px;
    height: 61px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    padding: 14px 40px 11px 33px;
    border-radius: 15px;
    transition: background-color 0.3s;
    border: 2px solid #d9d9d9;
    padding-left: 24px;

    .icon {
      width: 38px;
      height: 38px;
      margin-right: 29px;
    }

    span {
      color: #000000;
      font-weight: 700;
      line-height: 1.2;
      margin: 0;
    }
  }

  .des-login {
    line-height: 1.2;
    font-weight: 500;
    text-align: center;
    color: #0000007a;
    .des-underline {
      text-decoration: underline;
      text-decoration-thickness: 1.5px;
      text-underline-offset: 4.5px;
      cursor: pointer;
      color: #241d6e;
      font-weight: 700;
    }
  }
  .shadow-login {
    box-shadow: 5.16px 5.16px 15.47px 0px #e4e4e4e5 !important;
  }
  .btn-border {
    border: 2px solid #d9d9d9;
  }
  .icon-logo-footer {
    width: 32px;
    height: 32px;
  }
}
