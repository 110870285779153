.footer-login {
  width: 100%;
  height: 80px;

  & > :hover {
    cursor: pointer;
  }
  & > :not(:last-child) {
    margin-right: 10px;
  }
  & > :not(:first-child) {
    font-weight: 400;
    line-height: 0.9rem;
    color: #7d94ae;
    padding: 2px;
    text-decoration: none;
  }
}
