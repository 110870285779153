@import "../common/Font.scss";

.sidebar-container {
  display: flex;
  flex-direction: column;
  width: 280px; // サイドバーの幅
  height: 100%;
  min-height: calc(100vh - 62px - 80px);
  background: $background-color;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.2);
  padding: 10px;
  box-sizing: border-box;

  .account-select {
    display: flex;
    width: fit-content;
    min-width: 100px;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 31px;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    background: linear-gradient(145deg, #f2f2f2, #ffffff);
    box-shadow:
      3px 3px 7px #e7e7e7,
      -3px -3px 7px #ffffff;
    padding: 7px 10px;

    .small-avatar-button {
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      width: 17px;
      height: 17px;
      margin-right: 10px;
      img {
        width: 17px;
        height: 17px;
        border-radius: 50%;
      }
    }

    .account-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .select-triangle {
      margin-left: 5px;
      width: 20px;
      height: 20px;
      background: url("../../images/shapes/small_polygon.png") no-repeat center;
      background-size: contain;
      flex-shrink: 0;
    }
  }

  .sidebar-title-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px 0 4px;

    h3 {
      margin: 7.5px 0;
    }

    .button-container {
      .new-repository {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $main-color;
        border-radius: 5px;
        gap: 7px;
        box-shadow:
          2px 2px 4px 0px #00000033,
          2px 2px 8px 0px #ffffff;

        .folder-icon {
          flex: 1;
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .search-box {
    margin-bottom: 0px;
    width: 100%;
    padding: 0 7px 0 4px;

    input[type="text"] {
      width: 100%;
      box-sizing: border-box;
      padding: 7.25px;
    }
  }

  .repositories-container,
  .recent-activities-container {
    margin-bottom: 20px;
    .see-more-button {
      text-decoration: none;
      padding: 0;
    }
  }
}

h3 {
  margin-bottom: 10px;
}

ul.repositories-list {
  list-style: none;
  padding: 0 7px 0 0;

  li {
    display: flex;
    align-items: center;
    text-decoration: none;
    @include font-style(normal, $text-main-color);
    padding: 6px 5px;
    gap: 9px;
    margin-bottom: 2px;
    cursor: pointer;
    border-left: 2px solid transparent;
    span {
      color: $text-main-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    .repository-name {
      text-decoration: none;
    }
  }

  li:hover {
    border-left: 2px solid $active-color;
    background: $hover-color;
  }
}
.repository-not-found {
  padding: 0 6px;
}

button {
  padding: 5px 10px;
  background-color: $background-color;
  border: none;
  cursor: pointer;
}
