@import "../common/ColorPallete";

.move-item-to-folder-modal {
  .folder-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 18px;
    cursor: pointer;
    border-top: 1px solid $border-color;
    height: 46px;
  }
  .icon-with-name {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .folder-child-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 18px;
    cursor: pointer;
    padding-left: 25px;
  }
  .table-folder-structure {
    max-height: 60vh;
    overflow-y: auto;
    table {
      position: relative;
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px;
      table-layout: fixed;
      border-radius: 6px;
      overflow: hidden;
    }
  }
}
