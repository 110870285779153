.markdown-preview {
  line-height: 1.6;

  h1,
  h2,
  h3 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.3em;
    margin-top: 1em;
  }

  p {
    margin-bottom: 1em;
  }

  code,
  tt {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    white-space: break-spaces;
    background-color: #a4abb566;
    border-radius: 6px;
  }
}
