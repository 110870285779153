@import "../common/ColorPallete";

.drag-drop-wrapper {
  width: 100%;
  position: absolute;
  border: 3px dashed $border-color;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  color: #7d94ae;

  img {
    width: 42px;
    height: 42px;
  }

  h6 {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 12px;
  }

  p {
    margin-top: 0;
    font-size: 12px;
    line-height: 1.2;
    text-align: center;
  }

  &.dragging {
    display: flex;
  }
}
