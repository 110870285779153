@import "../common/Font.scss";

.header-right {
  display: flex;
  align-items: center;

  ul.dropdown-menu {
    display: none;
    position: absolute;
    background: white;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    left: -200px;
    width: 200px;
    top: 20px;
    padding: 10px;
    gap: 5px;

    li {
      display: flex;
      align-items: center;
      text-decoration: none;
      @include font-style(normal, $text-main-color);
      padding: 5px 10px;
      gap: 9px;
      margin-bottom: 2px;
      cursor: pointer;
      user-select: none;

      &::before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: gray;
      }
    }

    li:hover {
      border-radius: 7px;
      background: $main-color;
      color: white;
    }
  }

  .dropdown-menu.visible {
    display: grid;
  }

  .dropdown-info {
    position: relative;
    cursor: pointer;
    .avatar {
      width: 38px;
      height: 32px;
      padding: 0 3px;
      margin: 0 10px 0 15px;
      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: fill;
        border-radius: 50%;
      }
    }
  }

  .multi-language {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    .actions {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 31px;
      cursor: pointer;
      border-radius: 5px;
      padding: 0 7px;
      background: #fbfbfb;
      box-shadow:
        2px 2px 5px 0px #dededee5,
        -2px -2px 4px 0px #ffffffe5,
        2px -2px 4px 0px #dedede33,
        -2px 2px 4px 0px #dedede33,
        -1px -1px 2px 0px #dedede80 inset,
        1px 1px 2px 0px #ffffff4d inset;
      user-select: none;
    }

    ul.languages.dropdown-menu {
      left: 0px;
      width: 110px;

      li::before {
        content: "";
        display: none;
      }
    }
  }
}
