// base colors
$main-color: #048fad;
$main-accent-color: #126973;
$main-support-color: #00dba6;
$accent-color: #b7364d;
$support-color: #241d6e;
$background-color: #fbfbfb;
$text-main-color: #000000;
$text-reverse-color: #ffffff;
$text-lightgray-color: #7d94ae;
$color-placeholder: #7d94ae;
$box-shadow-input:
  1px 1px 3px 0px rgba(100, 100, 100, 0.36) inset,
  -1px -1px 2px 0px rgba(255, 255, 255, 0.36) inset,
  1px -1px 2px 0px rgba(100, 100, 100, 0.08) inset,
  -1px 1px 2px 0px rgba(100, 100, 100, 0.08) inset,
  -1px -1px 2px 0px rgba(100, 100, 100, 0.2),
  1px 1px 2px 0px rgba(255, 255, 255, 0.12);
$background-input: rgba(251, 251, 251, 0.4);
$hover-color: #eeeeee;
$active-color: #00dba6;
$border-color: #12697340;
