@import "./common/index.scss";

.layout-container {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 1024px;
  min-height: 100vh;
  height: auto;
  box-sizing: border-box;
  .content-container {
    min-height: calc(100vh - 80px);
  }
}

.layout-header {
  background-color: #f8f9fa;
  width: 100%;
  height: 62px; /* ヘッダーの高さ */
}

.layout-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: calc(100vh - 62px - 6px);
  margin-top: 6px;
}

.layout-main-directory {
  min-height: calc(100vh - 80px);
}

.layout-sidebar {
  height: unset;
  width: 280px; /* サイドバーの幅 */
}

.layout-content {
  flex-grow: 1; /* 残りのスペースを全て使用 */
  width: calc(100% - 280px); /* サイドバーの幅を引いた幅 */
}
