@import "../common/ColorPallete";

.conainer-wrapper {
  padding: 0 15px;
  margin-left: 8px;

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-left {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon-puzzle-piece {
        height: 100%;
        margin-right: 4px;
      }

      .text-header {
        color: #7d94ae;
      }
    }
  }

  .text-container {
    height: 191px;
  }

  .main-container {
    box-sizing: border-box;
    width: 100%;
    margin-top: 10px;
    padding: 0 45px;
    display: grid;
    grid-template-columns: repeat(4, minmax(310px, 310px));
    gap: 50px;

    .wrapper {
      margin: 10px 25px 75px 25px;

      .url-file {
        margin-bottom: 5px;
      }
    }

    @media screen and (max-width: 1700px) {
      grid-template-columns: repeat(3, minmax(310px, 310px));
    }

    @media screen and (max-width: 1366px) {
      grid-template-columns: repeat(2, minmax(310px, 310px));
    }
  }
}

.homeScreen {
  & &__top {
    grid-column-gap: 12px;
    column-gap: 12px;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    padding: 10px 10px 0 10px;
  }

  & &__lineEndTop {
    height: 1px;
    background-color: $border-color;
    margin: 0 -15px 10px -15px;
  }

  & &__left {
    padding: 10px 5px;

    &--title {
      h3 {
        font-weight: 700;
        color: #000;
        margin-top: 0;
        margin-bottom: 0;
      }

      p {
        line-height: 1.2;
        color: #7d94ae;
        margin-top: 0;
        margin-bottom: 5px;
      }

      p.note {
        font-style: italic;
        margin-top: 5px;
      }
    }
  }

  & &__right {
    padding: 10px;
  }

  & &__colLine {
    height: 100%;
    background-color: $border-color;
  }

  & &__line {
    height: 1px;
    background-color: $border-color;
  }
}

.homePage__createRepo--custom.repoInfo {
  .repoInfo__desc textarea {
    height: 73px;
  }
  .repoInfo__button {
    margin-top: 12px;
  }

  .repoInfo__radio {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .repoInfo__radio + .repoInfo__line {
    display: none;
  }

  .repoInfo__detail {
    padding: 5px 10px 10px 10px;
    margin-bottom: 5px;
    .repoInfo__repo {
      margin-bottom: 18px;
    }
  }
}
