.userInfoEdit {
  &__head {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px 20px 22px 0;
    margin-bottom: 10px;

    &--avatar {
      width: 135px;
      height: 135px;
      position: relative;

      img.image-avatar {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }

      img.icon-camera {
        position: absolute;
        right: 4px;
        bottom: 8px;
        cursor: pointer;
      }
    }

    & &--info {
      flex: 1;
    }

    button {
      width: 68px;
      height: 31px;
      font-size: 16px;
      font-weight: 700;
      border: none;
      border-radius: 4px;
    }
  }

  &__body {
    padding: 10px 0;

    &--description {
      margin-bottom: 20px;

      p.label {
        font-size: 16px;
        color: #7d94ae;
        margin: 0;
      }
    }

    &--item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 5px 12px;

      img {
        width: 18px;
        height: 18px;
        object-fit: contain;
      }
    }
  }

  textarea {
    width: 618px;
    height: 68px;
    resize: none;
    font-size: 16px;
    line-height: 1.2;
    padding-left: 8px;
  }

  input {
    height: 31px;
    width: 196px;
    display: block;
    padding-left: 6px;
  }

  input.input-error {
    border: 1px solid red;
  }

  p.text-error {
    font-size: 12px;
    color: red;
    margin-top: 4px;
  }

  input[readonly] {
    cursor: default;
  }

  .user-email {
    font-size: 18px;
    color: #7d94ae;
  }
}
