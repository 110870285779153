.name-repository-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;

  .wrapper-left-name {
    display: flex;
    align-items: center;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow:
        0 0 0 0 rgba(0, 0, 0, 0.2),
        0 2px 2px 0 rgba(0, 0, 0, 0.19);
    }

    .logo-directory {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }

    .logo-directory img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      margin-right: 0 !important;
    }

    .text-name-repository {
      font-weight: 700;
      padding: 2px 8px;
    }

    .type-repository {
      color: #126973;
      font-weight: 400;
    }
  }

  .wrapper-right-name {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon-people svg {
      width: 30px;
      height: 30px;
      padding: 4px;
    }

    .count-people {
      font-weight: 400;
      color: #7d94ae;
    }
  }
}
