@import "../common/ColorPallete";

.update-file-modal {
  .update-comment-title {
    width: 100%;
    line-height: 1.35rem;
    font-weight: 700;
  }
  .input-wrapper {
    margin-top: 5px;
  }
  .function-update-file {
    margin-top: 8px;
    background: rgba(251, 251, 251, 1);
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: 5px;
    .select-file-wrapper {
      display: flex;
      align-items: center;
      padding: 5px 0px 5px 0px;
      .file-select-update {
        display: none;
      }
      .custom-file-label {
        display: inline-block;
        padding: 8px 16px;
        cursor: pointer;
        background: rgba(251, 251, 251, 1);
        border-radius: 5px;
        box-shadow:
          2px 2px 5px 0px rgba(228, 228, 228, 0.9),
          -2px -2px 4px 0px rgba(255, 255, 255, 0.9),
          2px -2px 4px 0px rgba(228, 228, 228, 0.2),
          -2px 2px 4px 0px rgba(228, 228, 228, 0.2),
          -1px -1px 2px 0px rgba(228, 228, 228, 0.5) inset,
          1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
        font-weight: 700;
        line-height: 19.2px;
        text-align: left;
      }

      .file-name {
        display: flex;
        flex-direction: column;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        margin-left: 15px;
      }
    }
    .drag-drop-file-wrapper {
      margin-top: 10px;
      height: 69px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed rgba(18, 105, 115, 0.25);
      border-radius: 5px;
      .main-text {
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: rgba(125, 148, 174, 1);
      }
    }
    .dragging {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #54aeff;
    }
  }
}
