@import "../common/Font.scss";

.cd-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .cd-popup-container {
    width: auto;
    max-width: 820px;
    background: #fff;
    border-radius: 5px;
    padding: 15px 10px;
    .header-wrapper {
      display: flex;
      height: 49px;
      width: 800px;
      justify-content: space-between;
      .header-left {
        width: 25.8%;
        .text-header {
          font-size: 18px;
          font-weight: 700;
          line-height: 21.6px;
          text-align: left;
        }
      }
      .header-right {
        width: 72.8%;
        display: flex;
        justify-content: flex-end;
        .button-add-people {
          width: 118px;
          border-radius: 5px;
          background-color: rgba(4, 143, 173, 1);
          color: #fff;
          font-size: 15px;
          font-weight: 600;
          text-align: center;
          cursor: pointer;
          padding: 5.5px 12px;
          text-align: center;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
        }
      }
    }
    .main-wrapper {
      height: 483px;
      transform: translateY(-37px);

      .pPeopleManage {
        padding-top: 0;

        .pManage__list {
          overflow-y: auto;
          height: 425px;
        }
      }
    }
    .search-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 9px;
      .input-wrapper {
        width: 100%;
        height: 52px;
        padding-left: 5px;
        padding-right: 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        border: 1px solid $border-color;

        .dropdown-type {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 5px 10px;
          border-right: 1px solid $border-color;
          box-sizing: border-box;
          .text-dropdown {
            margin-left: 10px;
            font-size: 16px;
            font-weight: 700;
          }
          .icon-select-triangle {
            margin-left: 8px;
            margin-top: 5px;
          }
          .menu {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 5px 0;
            z-index: 1000; /* Đảm bảo menu hiển thị trên cùng */
          }

          .menu-item {
            padding: 8px 16px;
            cursor: pointer;
            margin-right: 0;
          }

          .menu-item:hover {
            background-color: #f0f0f0;
          }
        }
      }
      .button-add-request {
        width: 126px;
        border-radius: 5px;
        background-color: rgba(4, 143, 173, 1);
        color: #fff;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
        padding: 10px;
        box-sizing: unset;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      }
    }
    .wrapper-manage-people {
      width: 800px;
      height: 70px;
      border-bottom: 1px solid $border-color;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      .container-left {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        .wrapper-name-people {
          max-width: 185px;
          .text-main-name {
            width: 78px;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
            line-height: 15px;
            color: rgba(36, 29, 110, 1);
            border-bottom: solid 1px rgba(36, 29, 110, 1);
          }
          .text-name-role {
            font-size: 12px;
            font-weight: 400;
            line-height: 14.4px;
            text-align: left;
            margin-top: 8px;
            color: rgba(125, 148, 174, 1);
            min-width: 180px;
          }
        }
        .avt-member {
          margin: 0 20px 0 10px;
        }
      }
      .container-right {
        width: 70.1%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .wrapper-function {
          display: flex;
          justify-content: space-between;
        }
        .role-wrapper {
          position: relative;
          margin-right: 20px;
          .dropdown {
            position: relative;
            display: inline-block;
            width: fit-content;
            background: rgba(251, 251, 251, 1);
            padding: 10px;
            display: flex;
            border-radius: 3px;
            align-items: center;
            cursor: pointer;
            user-select: none;
            .dropdown-role {
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: rgba(125, 148, 174, 1);
            }
            .value-dropdown {
              font-size: 12px;
              font-weight: 400;
              text-align: left;
              color: rgba(0, 0, 0, 1);
              margin: 0 5px;
            }
            .dropdown-list {
              position: absolute;
              top: 100%;
              left: 0;
              background: rgba(251, 251, 251, 1);
              border-radius: 3px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
              width: 100%;
              z-index: 1;
              .dropdown-item {
                padding: 10px;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                color: rgba(0, 0, 0, 1);
                cursor: pointer;
              }

              .dropdown-item:hover {
                background-color: rgba(125, 148, 174, 0.2);
              }
            }
          }
        }
      }
    }
    .cd-buttons {
      display: flex;
      justify-content: end;
      width: 100%;
      button {
        border-radius: 5px;
        box-shadow: 2px 2px 4px 0 #0003;
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.2rem;
        margin-left: 15px;
        padding: 6px 14px;
      }
      .btn-cancel {
        margin-right: 0;
        margin: 0 10px 10px 0;
        padding: 8px 15px;
      }
    }
  }
}

.custom-checkbox {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid $border-color;
  cursor: pointer;
  position: relative;
  margin-right: 8px;
  margin-top: 5px;
}

.custom-checkbox img {
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(4, 143, 173, 1);
}

.custom-checkbox.checked {
  border: none;
}

.custom-checkbox.checked img {
  display: block;
}
