@import "./Font.scss";

input.common,
textarea.common {
  border-radius: 5px;
  border: none;
  outline: none;
  background: $background-input;
  box-shadow: $box-shadow-input;

  &::placeholder {
    color: $color-placeholder;
  }
}

.input-wrapper {
  width: 100%;
  height: 52px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  background: $background-color;

  .input-search {
    flex: 1;
    display: flex;
    height: 33px;
    align-items: center;
    border-radius: 5px;
    background: $background-input;
    box-shadow: $box-shadow-input;
    input {
      width: 100%;
      border: none;
      padding: 4px;
      border-radius: 5px 0 0 5px;
      background: none;
      box-shadow: none;
      &::placeholder {
        color: $color-placeholder;
      }
    }
    input:focus {
      outline: none;
    }
    .icon-search {
      padding: 4px;
      padding-left: 10px;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .line-middle {
    width: 1px;
    height: calc(100% + 20px);
    background-color: $border-color;
    margin-right: 14px;
    margin-left: 10px;
  }
}

.text-red {
  color: rgba(183, 54, 77, 1);
}

.border-solid {
  border: 1px solid $border-color;
  margin: 5px 15px;
}

button.common {
  height: 31px;
  background: #048fad;
  border-radius: 5px;
  box-shadow:
    2px 2px 4px 0px #00000033,
    2px 2px 8px 0px #ffffff;
  font-weight: 700;
  line-height: 1;
  text-align: left;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &.disabled {
    background: #ffffff;
    color: #000000;
    cursor: not-allowed;
  }
}

body,
.beauty-scroll {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0 !important;
    border-radius: 6px;
  }

  &.scroll-green::-webkit-scrollbar-thumb {
    background-color: $border-color !important;
  }

  &.scroll-small::-webkit-scrollbar {
    width: 3px;
  }
  &.scroll-small::-webkit-scrollbar-thumb {
    border-radius: 3px;
  }
}

.wrap-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  svg path {
    transition: fill 0.2s ease;
  }
}

.relative {
  position: relative;
}

.justify-items-center {
  justify-items: center;
}
