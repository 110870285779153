@import "../common/ColorPallete";

.container-repository {
  max-width: 752px;
  width: 100%;
  height: 518px;
  margin: auto;
  .header-container-repository {
    margin-top: 20px;
    .title-repository {
      font-weight: 700;
      line-height: 28.8px;
      text-align: left;
    }
    .description-repository {
      font-weight: 400;
      line-height: 19.2px;
      text-align: left;
      color: rgba(125, 148, 174, 1);
    }
  }
  .border-solid-header {
    height: 1px;
    width: 100%;
    background-color: $border-color;
    margin: 10px 0;
  }
  .main-repository {
    .fields-required {
      font-style: italic;
      text-align: left;
      color: rgba(125, 148, 174, 1);
      margin-bottom: 5px;
    }
    .wrapper-create-repository {
      padding: 10px;
      background: rgba(251, 251, 251, 1);
      .label-require {
        font-weight: 700;
        text-align: left;
      }
      .header-wrapper {
        display: flex;
        .owner-wrapper {
          .account-select {
            display: flex;
            min-width: 100px;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 31px;
            align-items: center;
            margin-top: 5px;
            cursor: pointer;
            border-radius: 5px;
            background: linear-gradient(145deg, #f2f2f2, #ffffff);
            box-shadow:
              3px 3px 7px #e7e7e7,
              -3px -3px 7px #ffffff;
            padding: 7px 10px;

            .small-avatar-button {
              align-items: center;
              justify-content: center;
              flex-wrap: nowrap;
              width: 17px;
              height: 17px;
              //margin-left: 5px;
              margin-right: 10px;
              border-radius: 15px;
              background: #d9d9d9;
              padding: 5px;
            }
          }
        }
        .source {
          padding: 7px;
          font-weight: 700;
          line-height: 60px;
          text-align: left;
        }
        .input-fill {
          flex: 1;
          display: flex;
          align-items: center;
          border-radius: 5px;
          margin-top: 5px;
          width: 185px;
          input {
            width: 100%;
            height: 31px;
            border: none;
            box-sizing: border-box;
            padding: 4px;
          }
          input:focus {
            outline: none;
          }
        }
      }
      .description-wrapper {
        padding: 10px;
        .input-description {
          margin-top: 5px;
          textarea {
            width: 100%;
            opacity: 70%;
            height: 91px;
            padding: 5px;
            box-sizing: border-box;
            resize: none;
          }
        }
      }
    }
  }
  .wrapper-status-repository {
    padding: 10px;
    background: rgba(251, 251, 251, 1);
    .radio-wrapper {
      display: flex;
      align-items: center;
      .description-radio {
        font-weight: 400;
        text-align: left;
        color: rgba(125, 148, 174, 1);
      }
    }
    .radio {
      width: 100px;
      margin: 0.5rem;
      margin-right: 20px;
      input[type="radio"] {
        position: absolute;
        opacity: 0;
        + .radio-label {
          &:before {
            content: "";
            background: #f4f4f4;
            border-radius: 100%;
            border: 1px solid rgba(0, 0, 0, 1);
            display: inline-block;
            width: 17px;
            height: 17px;
            position: relative;
            margin-right: 12px;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
            top: 4px;
          }
        }
        &:checked {
          + .radio-label {
            &:before {
              background-color: #fff;
              box-shadow: inset 0 0 0 4.5px rgba(4, 143, 173, 1);
            }
          }
        }
      }
      .radio-label {
        font-weight: 400;
        line-height: 15.2px;
        text-align: left;
      }
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    .button-create-repository {
      width: 138px;
      border-radius: 5px;
      background-color: rgba(4, 143, 173, 1);
      color: #fff;
      font-weight: 600;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
      padding: 3px;
      box-sizing: unset;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

.input-error {
  border: 1px solid red;
}
