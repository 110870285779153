@import "../common/Font.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 100%;
  height: 62px;
  background: #fbfbfb;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .header-left {
    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 4px;
  }

  .title-directory {
    margin-left: 4px;
  }

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;

    img {
      width: 24px; // アイコンのサイズを調整
      height: 24px;
    }
  }
  .button-nav {
    height: 31px;
    width: 31px;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 8px;
    box-shadow:
      0 0 0 0 rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.19);

    img {
      width: 24px;
      height: 24px;
    }
  }
  .button-nav:nth-child(1) {
    width: 53px;
  }
}

.header-directory {
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .header {
    background: #fbfbfb;
    box-shadow: none;
  }
  .menu-container {
    width: 100%;
    margin: 0 auto;
    padding: 10px 24px 0;
    background: #fbfbfb;
    .menu-list {
      display: flex;
      gap: 20px;

      .menu-item {
        position: relative;
        cursor: pointer;
        color: #7d94ae;
        padding: 8.5px 4px;
      }
      .menu-item.selected {
        color: #000;
        border-bottom: 2px solid #126973;
      }
    }
  }
}
