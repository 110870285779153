.alertCustom {
  background: #fff;
  min-width: 300px;
  max-width: calc(100% - 20px);
  position: fixed;
  z-index: 100000;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  display: flex;
  align-items: flex-start;
  gap: 8px;

  &.alert-success {
    border-color: #52c41a;
    background-color: #F6FFED;
  }

  &.alert-info {
    border-color: #1677FF;
    background-color: #E6F4FF;
  }

  &.alert-warning {
    border-color: #FAAD14;
    background-color: #FFFBE6;
  }

  &.alert-error {
    border-color: #FF4D4F;
    background-color: #FFF2F0;
  }

  .icon-alert {
    margin-top: 2px;
  }
}