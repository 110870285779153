.modal-confirm-container {
  position: fixed;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-confirm {
    width: 100%;
    max-width: 480px;
    background-color: white;
    padding: 15px 10px 15px 10px;
    border-radius: 5px;
  }
  .modal-confirm-title {
    width: 100%;
    line-height: 1.35rem;
    font-weight: 700;
    word-break: break-word;
  }
  .modal-confirm-content {
    width: 100%;
    padding: 15px 10px 30px 10px;
    line-height: 1.2rem;
    word-break: break-word;
    p {
      margin: 0;
    }
  }
  .modal-confirm-children {
    margin-top: -15px;
    padding-bottom: 30px;
  }
  .modal-confirm-action {
    width: 100%;
    display: flex;
    justify-content: end;
    & > button {
      padding: 6px 14px;
      font-weight: 700;
      line-height: 1.2rem;
      box-shadow: 2px 2px 4px 0px #00000033;
      margin-left: 15px;
      border-radius: 5px;
      transition: hover 0.5 ease;
      &:hover {
        box-shadow: 3px 3px 5px 1px #00000033;
      }
    }
  }
}
.text-white {
  color: white;
}
.bg-blue {
  background-color: #241d6e;
}
.bg-blue-light {
  background-color: #048fad;
}
.bg-red {
  background-color: rgba(183, 54, 77, 1);
}
