.myPageContent {
  width: 678px;
  max-width: 100%;
  min-height: 600px;
  margin: 0 auto;
  padding: 0 20px 40px;

  &__body {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 310px));
    gap: 10px;
  }
}
