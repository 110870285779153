@tailwind components;
@tailwind utilities;
@import "assets/scss/common/Font.scss";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $font-family-en, $font-family-ja, sans-serif;
  .font-en {
    font-family: $font-family-en;
  }

  .font-ja {
    font-family: $font-family-ja;
  }
}

body.lang-en {
  .font-en-10 {
    font-size: 10px;
  }

  .font-en-12 {
    font-size: 12px;
  }

  .font-en-14 {
    font-size: 14px;
  }

  .font-en-16 {
    font-size: 16px;
  }

  .font-en-18 {
    font-size: 18px;
  }

  .font-en-20 {
    font-size: 20px;
  }

  .font-en-22 {
    font-size: 22px;
  }

  .font-en-24 {
    font-size: 24px;
  }
}

body.lang-ja {
  .font-ja-10 {
    font-size: 10px;
  }

  .font-ja-12 {
    font-size: 12px;
  }

  .font-ja-14 {
    font-size: 14px;
  }

  .font-ja-16 {
    font-size: 16px;
  }

  .font-ja-18 {
    font-size: 18px;
  }

  .font-ja-20 {
    font-size: 20px;
  }

  .font-ja-22 {
    font-size: 22px;
  }

  .font-ja-24 {
    font-size: 24px;
  }
}

button {
  font-family: inherit;
}

input,
textarea,
::placeholder {
  font-family: $font-family-ja, sans-serif;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
