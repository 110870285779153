@import "../common/ColorPallete";

.hover-menu-container {
    padding: 3px 7px;
    background: $hover-color;
    box-shadow: -5px 2px 10px 0.5px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    height: 28px;
    .hover-menu {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .tooltip-container {
            height: 15px;
            width: 15px;
            .hover-icon {
                padding: 6px;
                cursor: pointer;
                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
            .tooltip-text {
                visibility: hidden;
                white-space: nowrap;
                background-color: #555;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 8px;
                position: absolute;
                z-index: 1;
                bottom: 150%;
                left: 75%;
                transform: translateX(-50%);
                opacity: 1;
                transition: opacity 0.3s;
                &::after {
                    content: "";
                    position: absolute;
                    top: 100%;
                    /* Arrow at the bottom of the tooltip */
                    left: 50%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: #555 transparent transparent transparent;
                }
            }
            &:hover .tooltip-text {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
