@import "../common/Font.scss";

.logo-ticket img {
  margin-right: 0 !important;
}

.container-ticket {
  padding: 0 79px;
  .search-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 0;
    .input-wrapper {
      margin-right: 14px;
      .dropdown-type {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 0 5px 10px;
        box-sizing: border-box;
        .text-dropdown {
          font-weight: 700;
        }
        .icon-select-triangle {
          margin-left: 8px;
          margin-top: 5px;
          position: relative;
          img {
            cursor: pointer;
          }
          .type-request-wrapper {
            position: absolute;
            background-color: white;
            border: 1px solid #ccc;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            z-index: 1000;
            top: 100%;
            left: 0;
            .requested-review-menu {
              width: 185px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .checkbox-review {
                height: 18px;
                width: 18px;
              }
              .avatar-requested-review {
                height: 25px;
                margin-left: 5px;
              }
              .name-requested-review {
                margin-left: 5px;
                white-space: nowrap;
              }
              .name-requested-review.all-statuses {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .button-add-request {
      width: 128px;
      height: 41px;
      border-radius: 5px;
      background-color: rgba(4, 143, 173, 1);
      color: #fff;
      font-weight: 700;
      line-height: 1;
      cursor: pointer;
      box-sizing: unset;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .table-review-wrapper {
    margin-top: 10px;
    .thead-wrapper {
      border: 1px solid $border-color;
      border-radius: 5px;
      overflow: hidden;
      table {
        border-spacing: 0;
        width: 100%;
      }
      thead {
        background: rgba(251, 251, 251, 1);

        th {
          height: 56px;
          padding: 10px 9px;
          text-align: left;
          background: rgba(251, 251, 251, 1);
          font-weight: 400;
          box-sizing: border-box;
          &:nth-child(1) {
            width: 3.5%;
          }
          &:nth-child(2) {
            width: 22%;
          }
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            width: 16%;
          }
        }
      }
    }
    .custom-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 10px;
      tbody {
        position: relative;

        tr {
          height: 61px;
          padding: 0px 10px;
          border-radius: 5px;
          background: rgba(255, 255, 255, 1);
          box-shadow:
            3px 3px 8px 0px rgba(237, 237, 237, 0.9),
            -3px -3px 6px 0px rgba(255, 255, 255, 0.9),
            3px -3px 6px 0px rgba(237, 237, 237, 0.2),
            -3px 3px 6px 0px rgba(237, 237, 237, 0.2),
            -1px -1px 2px 0px rgba(237, 237, 237, 0.5) inset,
            1px 1px 2px 0px rgba(255, 255, 255, 0.3) inset;
          overflow: hidden;

          margin-bottom: 10px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
          td {
            box-sizing: border-box;
            &:nth-child(1) {
              padding-left: 10px;
              width: 3.5%;
              font-size: 16px;
              font-weight: 400;
              line-height: 19.2px;
              text-align: left;
              color: rgba(125, 148, 174, 1);
            }
            &:nth-child(2) {
              width: 22%;
              padding-left: 10px;
            }
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              width: 16%;
              padding-left: 10px;
            }
            &:nth-child(7) {
              padding-left: 8px;
            }
            .name-repo-wrapper {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              word-break: break-word;
              a {
                text-decoration: none;
                color: #000;
                font-weight: 400;
                font-size: 16px;
              }
              .text-name {
                padding-left: 10px;
                line-height: 1;
                text-align: left;
              }
            }
            .text-comment {
              padding-left: 6px;
              box-sizing: border-box;
              word-break: break-word;
            }
            .assignee-wrapper,
            .owner-wrapper,
            .requested-review-wrapper {
              box-sizing: border-box;
              display: flex;
              align-items: center;
              .name-assignee,
              .name-owner,
              .type-requested-review {
                margin-left: 10px;
              }
            }
            .icon-repo,
            .avatar-owner,
            .avatar-requested-review,
            .avatar-assignee,
            .img-status {
              height: 25px;
              width: 25px;
              img {
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
  .no-data {
    width: 100%;
    text-align: center;
    padding: 38px 0;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    color: #7d94ae;
  }
}
.text-title {
  font-weight: 700;
  font-size: 24px;
}
.text-additional {
  font-weight: 700;
  font-size: 24px;
  padding-left: 20px;
  color: #7d94ae;
}
.container-ticket-detail {
  display: flex;
  background: none;
  min-height: calc(100vh - 122px);
  width: 100%;
  padding-top: 3px;
  .left-ticket-detail {
    width: 75%;
    background-color: #ffffff;
    min-height: calc(100vh - 122px);
    position: relative;
    padding-bottom: 80px;
    .footer-login {
      position: absolute;
      bottom: 0;
    }
    .container-title {
      box-sizing: border-box;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      box-shadow: 0 1px 0 0 $border-color;
      width: 100%;
    }
    .box-content-body {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 30px;
      box-sizing: border-box;

      .custom-body {
        width: calc(100% / 2 - 30px / 2);
        .action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          .text {
            font-weight: 700;
          }
          .btn-apply {
            width: 70px;
            height: 29px;
            &:hover {
              opacity: 0.85;
              box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
            }
          }
        }
        .viewer {
          :deep(canvas) {
            width: 100%;
          }
        }
      }
    }
    .container-footer {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .file {
        width: 50%;
        margin: 10px;
        .title {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
          div:first-child {
            margin-right: 40px;
            font-weight: 700;
            size: 18px;
            line-height: 21px;
          }
          div:last-child {
            color: #7d94ae;
            font-size: 14px;
          }
        }
        .file-name div:last-child {
          color: #126973;
          font-size: 14px;
        }
        .file-info {
          margin-top: 8px;
          .title {
            margin-right: 40px;
            font-weight: 700;
            size: 18px;
            line-height: 21px;
            margin-bottom: 12px;
          }
          .list-info {
            .row {
              color: #7d94ae;
              font-size: 14px;
              display: flex;
              .col {
                margin-right: 60px;
                div {
                  display: flex;
                  gap: 10px;
                }
              }
            }
          }
        }
        .file-history {
          margin-top: 12px;
          .title {
            margin-right: 40px;
            font-weight: 700;
            size: 18px;
            line-height: 21px;
            margin-bottom: 12px;
          }
          .list-history {
            color: #7d94ae;
            font-size: 14px;
            p {
              display: flex;
              gap: 12px;
              margin: 0 0 7px;
            }
          }
        }
      }
    }
  }
  .right-ticket-detail {
    width: 25%;
    background-color: #fbfbfb;
    padding-left: 10px;
    position: relative;
    min-height: calc(100vh - 122px); /* 122px is the height of the header */
    padding-bottom: 120px; /* 120px is the height of the chat-action */
  }
}

.container-action {
  padding: 0 15px;
  margin-right: 3px;

  .play-back-section {
    .action-group {
      width: 35px;
      height: 35px;
      margin-left: 0;
    }
    .time-group {
      color: #241d6e;
    }
    input.custom-range {
      &::-webkit-slider-thumb {
        box-shadow: -390px 0 0 400px #241d6e;
      }
    }
  }

  .title.title-content {
    font-weight: 700;
    font-size: 18px;
  }
  .action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 63px;

    .time-line {
      width: 63%;
      padding: 4px 0px;
      .play-back-section {
        position: relative;
        bottom: unset;
        height: unset;
      }
    }
    :deep(.action-group img) {
      color: #241d6e !important;
      fill: #241d6e !important;
    }
  }
  .controller {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    .btn-controller {
      background-color: #ffffff;
      box-shadow:
        2px 2px 5px 0px #e4e4e4e5,
        -2px -2px 4px 0px #ffffffe5,
        2px -2px 4px 0px #e4e4e433,
        -2px 2px 4px 0px #e4e4e433,
        -1px -1px 2px 0px #e4e4e480 inset,
        1px 1px 2px 0px #ffffff4d inset;
      padding: 6.5px;
      margin: 10px;
      transition: box-shadow 0.5s ease-in-out;
      &:focus-visible {
        outline: none;
      }
    }
  }
}
.container-body {
  padding: 12px 16px;
}

.btn-status {
  padding: 6px 8px;
  color: #ffffff;
  background-color: #00dba6;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  box-shadow:
    2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    2px 2px 8px 0 #fff;
  transition:
    opacity 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    opacity: 0.85;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
.text-branch {
  color: #000;
  line-height: 19px;

  .text-name-user {
    font-weight: 700;
    margin-right: 6px;
  }
  .text-info {
    font-weight: 400;
    margin-right: 6px;
  }
  .text-name-repo {
    font-weight: 700;
  }
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.branch-box {
  .btn-status {
    margin-right: 6px;
  }
}
.mr-15 {
  margin-right: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.bg-gray {
  background-color: #fbfbfb !important;
}
.bg-light-blue {
  background-color: #e1ffc7;
}
.row-reverse {
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.ml-10 {
  margin-left: 10px;
}
.mr-6 {
  margin-right: 6px;
}
.ml-6 {
  margin-left: 6px;
}
.round-user {
  border-radius: 10px 0 10px 10px !important;
}
.btn {
  padding: 8px 16px;
  background-color: #048fad;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.85;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}

div {
  box-sizing: border-box;
}
.btn-hover {
  &:hover {
    opacity: 0.85;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
}
.purple-color {
  color: #241d6e !important;
  fill: #241d6e !important;
}
.success-file-color {
  color: #00dba6;
}
.error-file-color {
  color: #b7364d;
}

.ticket-detail-screen + .footer-login {
  display: none;
}
