@import "../common/Font.scss";
@import "../common/ColorPallete";

.modalAddMember {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    box-sizing: border-box;
  }
  & &__wrapper {
    background: #fff;
    min-width: 600px;
    max-width: 820px;
    padding: 15px 10px;
    border-radius: 5px;
    flex-wrap: wrap;

    h4 {
      width: 100%;
      margin: 0 0 5px 0;
    }
  }

  & &__list {
    margin-top: 6px;
    margin-bottom: 10px;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  & &__item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 6px 10px;
    cursor: pointer;

    img.avatar {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;

      .email {
        font-size: 12px;
        line-height: 1;
        font-weight: 700;
        color: #241d6e;
        display: inline;
        margin-top: 0;
        text-decoration: none;
      }

      .name {
        font-size: 10px;
        color: #7d94ae;
      }
    }

    img.close {
      width: 16px;
      height: 16px;
      margin-right: 6px;
      cursor: pointer;
    }

    &:hover {
      background-color: #eee;
    }

    &--selected {
      background-color: #ddf4ff;
      border: 1px solid #a7d8ff;
      border-radius: 5px;
      cursor: default;
      margin-bottom: 20px;
    }
  }

  & &__radio {
    background-color: #fbfbfb;
    padding: 10px;
    margin-bottom: 7.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--item {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 4px 0;

      input {
        display: none;
      }

      input + label {
        cursor: pointer;
        display: inline-block;
        background: none;
        border: 1px solid #000000;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        margin: 2px;
      }

      input:checked + label {
        border: 5px solid #048fad;
      }

      .label {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: #000000;
        padding: 0 4px;
        width: 75px;
      }

      span {
        flex: 1;
        font-size: 16px;
        color: rgba(125, 148, 174, 1);
      }
    }
  }

  & &__button {
    width: 100%;
  }

  & &__wrapBtn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    .btn-cancel {
      width: 65px;
      padding: 0;
      background-color: #fff;
      color: #000;
    }
  }

  .spinner-small {
    border-width: 2px;
    width: 20px !important;
    height: 20px !important;
  }
}
