@import "../common/ColorPallete";

.repo-item {
  height: 46px;
  cursor: pointer;
  user-select: none;
  .icon-with-name {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .icon-with-name img {
    margin-right: 10px;
  }
  td {
    border-bottom: 1px solid #d0d7de !important;
  }
  td {
    padding: 0 5px;
    text-align: left;
    font-weight: normal;
    &:nth-child(2) {
      padding-left: 16px;
      border-left: 2px solid transparent;
    }
    &:last-child {
      text-align: right;
      padding-right: 18px;
    }
  }
  .td-checkbox {
    padding: 8px 30px 8px 15px;
    border-bottom: none !important;
    width: 18px;
    background: #ffffff !important;
    .wrapper-checkbox {
      width: 18px;
      height: 18px;
    }
  }
  .col-name {
    width: 40%;
    .item-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .item-msg {
    width: 32%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .item-update-time {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @function calc-padding($depth) {
    @return 18px + 18px * $depth;
  }

  @for $i from 1 through 10 {
    &.item-depth-#{$i} .col-name {
      padding-left: calc-padding($i);
    }
  }

  &.item-hidden {
    display: none !important;
  }

  &.active {
    background-color: $hover-color;
    td:nth-child(2) {
      border-left: 2px solid $active-color;
    }
    .item-msg {
      border-left: 2px solid transparent !important;
    }
  }
  &:hover {
    background-color: $hover-color;
  }
}
