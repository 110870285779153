@import "../common/Font.scss";

.publicLayout {
  width: 1000px;
  margin: 36px auto 120px;
  color: #000;
  font-family: "Noto Sans JP", sans-serif !important;

  & &__titlePage {
    font-weight: 700;
    font-size: 20px;
  }

  .line {
    width: 100%;
    height: 1px;
    background-color: $border-color;
    margin: 10px 0 12px;
  }

  .margin-b-10 {
    margin-bottom: 10px;
  }

  .margin-l-10 {
    margin-left: 10px;
  }

  & h1 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
  }

  & h3 {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  & span {
    font-size: 12px;
    color: #7d94ae;
  }

  & p {
    margin: 0;
    font-size: 12px;
    line-height: 17.38px;
  }

  a {
    color: #000;
  }
}
