@import "../common/ColorPallete";

.repoInfo {
  * {
    box-sizing: border-box;
  }

  & &__line {
    height: 1px;
    width: 100%;
    background-color: $border-color;
  }

  & &__detail {
    padding: 10px;
    background-color: #fbfbfb;
    margin-bottom: 8px;
  }

  & &__repo {
    display: flex;
    align-items: flex-end;
    gap: 7px;
    margin-bottom: 20px;
  }

  & &__owner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &--wrap {
      display: flex;
      min-width: 100px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      height: 31px;
      align-items: center;
      margin-top: 5px;
      border-radius: 5px;
      background: linear-gradient(145deg, #f2f2f2, #ffffff);
      box-shadow:
        3px 3px 7px #e7e7e7,
        -3px -3px 7px #ffffff;
      padding: 7px 10px;
    }
    input {
      box-sizing: border-box;
      display: flex;
      gap: 4px;
      align-items: center;
      height: 31px;
      padding: 5px 6px 6px;
      border-radius: 5px;
      background: rgba(251, 251, 251, 0.4);
      box-shadow:
        1px 1px 3px 0px rgba(100, 100, 100, 0.36) inset,
        -1px -1px 2px 0px rgba(255, 255, 255, 0.36) inset,
        1px -1px 2px 0px rgba(100, 100, 100, 0.08) inset,
        -1px 1px 2px 0px rgba(100, 100, 100, 0.08) inset,
        -1px -1px 2px 0px rgba(100, 100, 100, 0.2),
        1px 1px 2px 0px rgba(255, 255, 255, 0.12);
    }

    input {
      border: none;
      outline: none;
      font-weight: 700;
      box-shadow:
        1px 1px 3px 0px rgba(100, 100, 100, 0.36) inset,
        -1px -1px 2px 0px rgba(255, 255, 255, 0.36) inset,
        1px -1px 2px 0px rgba(100, 100, 100, 0.08) inset,
        -1px 1px 2px 0px rgba(100, 100, 100, 0.08) inset;
    }

    &--avatar {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &--name {
      font-weight: 700;
      margin: 0;
    }

    &--triangle {
      width: 20px;
      height: 20px;
      background: url("../../images/shapes/small_polygon.png") no-repeat center;
      background-size: contain;
      flex-shrink: 0;
    }
  }

  & &__label {
    font-weight: 700;
    margin-bottom: 2px;
  }

  & &__slash {
    font-weight: 700;
    transform: translateY(-3px);
  }

  & &__desc {
    textarea {
      border: none;
      border-radius: 5px;
      box-shadow:
        inset 1px 1px 3px 0 #6464645c,
        inset -1px -1px 2px 0 #ffffff5c,
        inset 1px -1px 2px 0 #64646414,
        inset -1px 1px 2px 0 #64646414,
        -1px -1px 2px 0 #64646433,
        1px 1px 2px 0 #ffffff1f;
      height: 91px;
      line-height: 1.2;
      outline: none;
      padding: 4px 10px;
      resize: none;
      width: 100%;
      background-color: #fbfbfb;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #8c8c8c;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #8c8c8c;
      }
    }
  }

  & &__error {
    display: flex;
    position: relative;
    padding-bottom: 14px;
    flex-direction: column;
    input,
    textarea {
      border: 1px solid red !important;
    }
    .text-error {
      position: absolute;
      color: red;
      margin-top: 5px;
      bottom: 0;
    }
  }

  & &__radio {
    background-color: #fbfbfb;
    padding: 10px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &--item {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 4px 0;

      input {
        display: none;
      }

      input + label {
        cursor: pointer;
        display: inline-block;
        background: none;
        border: 1px solid #000000;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        margin: 2px;
      }

      input:checked + label {
        border: 5px solid #048fad;
      }

      .label {
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        color: #000000;
        padding: 0;
        width: 75px;
      }

      span {
        flex: 1;
        color: rgba(125, 148, 174, 1);
      }
    }
  }

  & &__button {
    width: 138px;
    height: 31px;
    font-weight: 700;
    color: #fff;
    background-color: #048fad;
    border-radius: 5px;
    float: right;
    margin-top: 22px;
  }
}
