.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Màu nền mờ */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .loading-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;

    .loading-animation-wrapper {
      position: absolute;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      width: 100px;

      .loading-animation {
        position: absolute;
        height: 100px;
        width: 100px;
      }
    }

    .progress-circle {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: none;
    }

    .progress-background,
    .progress-foreground {
      fill: none;
    }

    .progress-background {
      stroke: #e0e0e0;
    }

    .progress-foreground {
      stroke: #3498db;
      transition: stroke-dashoffset 0.35s;
    }
  }

  &.loading {
    display: flex;
  }
}
